var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        "d-flex",
        "global-comment",
        "align-center",
        "flex-column",
        _vm.rightSideTabOpen
          ? "global-comment-info-tab-active"
          : "global-comment-default",
      ],
    },
    [
      _c("cs-comment-input", {
        attrs: {
          tooltip: _vm.selectedIds.length
            ? _vm.$t("Create object specific comment")
            : _vm.$t("Create global comment for model"),
          commentBubbleBtnColor: "primary",
          disabled: _vm.commentLoading,
          commentBubbleIcon: _vm.selectedIds.length
            ? "AddCommentIcon"
            : "AddGlobalCommentIcon",
        },
        on: { sendBtnClicked: _vm.handleCommentClick },
        model: {
          value: _vm.globalCommentActive,
          callback: function ($$v) {
            _vm.globalCommentActive = $$v
          },
          expression: "globalCommentActive",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import { CsCommentInput } from '@consteel/csuetify'
import { guid } from '@consteel/storm/src/Models/ModelBase'
import { Vector3 } from '@consteel/straw'
import Vue from 'vue'
import { createComment, getCommentLoading } from '../../../modules/comment'
import {
  getCommentSettings,
  modelViewerStore,
  removeFloatingCommentInputs,
  removeSelection,
  setCommentSelectTypeBySelectedIds,
  setCurrentCamera,
} from '../../../store/modelViewer.store'

export default Vue.extend({
  name: 'GlobalCommentInput',
  components: {
    CsCommentInput,
  },
  data() {
    return {
      globalCommentActive: true,
      objectSpecificCommentActive: true,
      showCommentInputs: false,
    }
  },
  props: {
    modelId: {
      type: String,
      default: '',
    },
  },
  computed: {
    objectSpecificCommentDisabled(): boolean {
      return !this.selectedIds.length
    },
    commentLoading(): boolean {
      return getCommentLoading()
    },
    rightSideTabOpen() {
      return modelViewerStore.activeSideTab !== 0
    },
    selectedIds(): guid[] {
      return modelViewerStore.selectedIds
    },
  },
  methods: {
    commentBubbleClicked(): void {
      this.showCommentInputs = !this.showCommentInputs
    },
    async handleCommentClick(
      commentText: string,
      stopSendBtnLoadingCallback: () => void
    ): Promise<void> {
      setCurrentCamera()
      const smadsteelIds = modelViewerStore.selectedIds
      let centerPoint = undefined as Vector3 | undefined

      if (smadsteelIds.length) {
        centerPoint = modelViewerStore.currentView?.GetObjectCenterPoint(smadsteelIds[0])
      }

      await createComment(
        commentText,
        this.modelId,
        getCommentSettings(),
        this.$route.params.historyId,
        smadsteelIds,
        centerPoint ? { x: centerPoint.x, y: centerPoint.y, z: centerPoint.z } : undefined
      )

      stopSendBtnLoadingCallback()
      setCommentSelectTypeBySelectedIds()
      await removeFloatingCommentInputs()
      removeSelection()

      this.globalCommentActive = true
    },
  },
})
